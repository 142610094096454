import Api from '@/services/Api'

export default {
  getStatus() {
    return Api(undefined, { hideAlert: true }).get('/api/status')
  },
  getSetting() {
    return Api(undefined, { hideAlert: true }).get('/api/status/setting')
  },
  updateSetting(params) {
    return Api().put('/api/status/setting', params)
  },
  healthCheck() {
    return Api().get('/api/status/healthCheck')
  },
}
